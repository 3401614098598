import _objectSpread from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { QUERY, PATCH } from "@/core/services/store/request.module";
export default {
  name: "login-1",
  data: function data() {
    return {
      isLoading: false,
      isPageLoaded: false,
      isPageExpired: false,
      errors: [],
      forgotEmail: null,
      forgotPassword: null,
      forgotConfirmPassword: null,
      dbUser: {},
      token: null
    };
  },
  computed: {
    backgroundImage: function backgroundImage() {
      return process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg";
    }
  },
  methods: {
    removeError: function removeError(index) {
      this.errors.splice(index, 1);
    },
    getUser: function getUser() {
      var _this = this;

      this.$store.dispatch(QUERY, {
        url: 'auth/forgot',
        data: _objectSpread({}, this.$route.query)
      }).then(function (_ref) {
        var token = _ref.token,
            user = _ref.user;
        _this.dbUser = user;
        _this.token = token;
        _this.isPageLoaded = true;
        _this.isPageExpired = false;
      }).catch(function () {
        _this.isPageExpired = true;
        _this.isPageLoaded = true;
      });
    },
    submitLogin: function submitLogin() {
      var _this2 = this;

      this.errors = [];

      if (this.forgotPassword !== this.forgotConfirmPassword) {
        this.errors.push('Password and confirm password does not match.');
        return false;
      }

      var submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.isLoading = true;
      this.$store.dispatch(PATCH, {
        url: 'auth/forgot',
        data: _objectSpread(_objectSpread({}, this.$route.query), {}, {
          'password': this.forgotPassword,
          'password_confirmation': this.forgotConfirmPassword,
          'oauth-token': this.token
        })
      }).then(function () {
        var resolved = _this2.$router.resolve({
          name: "login",
          query: {
            email: _this2.dbUser.email,
            oauthx: 'success'
          }
        });

        window.location.href = resolved.href;
      }).catch(function (_ref2) {
        var data = _ref2.data;
        _this2.errors = data.data;
        _this2.isLoading = false;
        submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
      });
    }
  },
  mounted: function mounted() {
    this.getUser();
  }
};